<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('app.route.categories')" back-button>
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn
          text
          small
          color="green"
          @click="
            $$router.push({
              name: 'manager.store.category',
              params: { categoryUuid: 'new', sectionUuid: getSectionUuidParam, storeId: $route.params.storeId },
            })
          "
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn text small color="primary" :disabled="!selected" @click="editItem()">
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          color="red"
          v-if="$hasRole('SUPER-ADMIN') || $hasRole('ADMIN') || $hasRole('STORE-MANAGER')"
          :disabled="!selected"
          @click="confirmDelete()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(104)">
        <v-data-table
          dense
          :headers="headers"
          :items="getCategories"
          item-key="id"
          :search="search"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "SectionCategories",
  components: { ViewContainer, Toolbar },
  props: {
    regionId: {
      typeof: Number,
      default() {
        return -1;
      },
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 70 },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("general.nameAr"), value: "nameAr" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.clearSelectedCategory();

    this.loadCategoriesByParentUuid(this.getSectionUuidParam).then(() => {
      this.loading = false;
    });

    // subscribe to reactivity actions
    this.setReactivityParams({
      resource: "CATEGORIES",
      scope: "section",
      key: this.getSectionUuidParam,
    });
    this.$sub("reactivity/CATEGORIES", (payload) => {
      this.loading = true;
      if (payload.mode !== "Delete") {
        this.reloadCategoriesByParentUuid({
          storeId: this.getStoreIdParam,
          categoryIds: [payload.category],
        }).then(() => (this.loading = false));
      } else {
        this.removeCategoriesFromState(payload.category).then(() => (this.loading = false));
      }
      console.log("reactivity/CATEGORIES:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("categories", ["getCategories"]),
    ...mapState({
      selected(state) {
        return state.categories.selected;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
    getRegionIdParam() {
      return parseInt(this.$route.params.regionId);
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getSectionUuidParam() {
      return this.$route.params.sectionUuid;
    },
  },
  methods: {
    ...mapActions("categories", [
      "loadCategoriesByParentUuid",
      "reloadCategoriesByParentUuid",
      "removeCategoriesFromState",
      "deleteCategory",
      "setSelectedCategory",
      "clearSelectedCategory",
    ]),
    ...mapActions("ui", [
      "showDialog",
      "closeDialog",
      "setReactivityParams",
      "clearReactivityParams",
    ]), //, "setBreadcrumbs"

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedCategory(event.item);
      } else if (event.id && this.selected?.id !== event.id) {
        this.setSelectedCategory(event);
      } else {
        this.clearSelectedCategory();
      }
    },
    editItem() {
      if (this.selected) {
        this.$$router.push({
          name: "manager.store.category",
          params: { storeId: this.getStoreIdParam,  sectionUuid: this.getSectionUuidParam, categoryUuid: this.selected.uuid, back: { name: this.$route.name, params: this.$route.params } },
        });
      }
    },
    confirmDelete() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} section?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteCategory(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
  },
};
</script>
